import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import {graphql} from 'gatsby'
import ModifiedHelmet from '../components/modified-helmet'

const IndexPage = ({data}) => (
  <Layout>
    <ModifiedHelmet seo={data.datoCmsWebsiteLayout.seoMetaTags} />
    <Page widgets={data.datoCmsWebsiteLayout.homePage.widgets} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    datoCmsWebsiteLayout {
      homePage {
        ...Widgets
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
